import "./_Logo.scss";
import cookies from "js-cookie";
import arabicLogo from "../../assets/icons/arabicLogo.svg";
import englishLogo from "../../assets/icons/englishLogo.svg";

function Logo() {
  const currentLanguageCode = cookies.get("i18next") || "en";

  return (
    <a style={{ cursor: "pointer" }} href="https://aqari360-ta.com">
      <img
        className="img_logo"
        src={currentLanguageCode === "en" ? englishLogo : arabicLogo}
        alt=""
      />
    </a>
  );
}

export default Logo;
